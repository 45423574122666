import { useEffect, useState } from 'react';
import { MasterLayout } from 'components/MasterLayout/MasterLayout';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Camera } from 'components';

const DocumentInformationPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (isSent) {
      navigate('/document-information');
    }
  }, [isSent, navigate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTakePhoto = (dataUri: string) => {
    console.log('🚀 ~ file: DocumentInformationPage.tsx ~ line 26 ~ onTakePhoto ~ dataUri', dataUri);
    setOpen(false);
    setIsSent(true);
  };

  return (
    <MasterLayout>
      <Box sx={{ flexGrow: 1 }}>
        <h4>CNH</h4>
        <p>Algum texto informativo</p>
        <div className="d-flex justify-content-end py-6 px-9">
          <Button variant="contained" onClick={handleClickOpen}>
            Tirar Foto
          </Button>
        </div>

        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Tirar foto CNH
              </Typography>
            </Toolbar>
          </AppBar>
          <Camera onTakePhoto={onTakePhoto} example={null} index={0}/>
        </Dialog>
      </Box>
    </MasterLayout>
  );
};

export default DocumentInformationPage;
