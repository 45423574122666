import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { MasterLayout } from 'components/MasterLayout/MasterLayout';
import { fetchInspection, inspectionSelector } from 'reducers/inspection';
import { useAppDispatch, useAppSelector } from 'store';
import { InvalidInspection } from 'components';
import CardInformation from 'components/CardInformation/CardInformation';
import { cardInformationPicture1, cardInformationPicture2 } from 'components/CardInformation/informationMock';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
// import { initiateInspectionChange } from 'api';

const InspectionPage = () => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dateExpire, setDateExpire] = useState<Date>();
  const inspection = useAppSelector(inspectionSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(fetchInspection(token))
        .catch((err) => {
          toast.error(`${err.response.statusText}`, {
            position: 'top-right',
            autoClose: false,
          });
        })
        .finally(() => {
          setDateExpire(new Date(Date.parse(inspection.inspection?.expiresAt ?? '')));
          setIsLoading(false);
        }).then((item) =>{
          if(inspection.inspection['value'] === null){
            navigate('/rejected-token');
          }
          return '';
        });
    }
  }, [dispatch, token]);

  const initiateInspection = async() => {
    if(inspection.inspection.started === 0 || inspection.inspection.started === null || inspection.inspection.started === undefined){
      // await initiateInspectionChange(inspection?.token ?? '').then(async(value)=>{
      //   await dispatch(fetchInspection(token ?? ''));
      //   console.log(inspection);
      // });
    };
    navigate('./wizard');
  }

  const renderContent = useMemo(() => {
    if (isLoading) return <CircularProgress />;
    if (inspection.error) return <InvalidInspection token={token ?? ''} error={inspection.error} />;
    setDateExpire(new Date(Date.parse(inspection.inspection?.expiresAt ?? '')));
    return (
      <>
        {/* <p>Informação sobre o processo de vistoria</p> */}
        <div className="d-flex justify-content-end py-6 px-9">
          <button
            type="button"
            className="btn btn-primary"
            onClick={initiateInspection}
          >
            Iniciar Vistoria
          </button>
        </div>
      </>
    );
  }, [isLoading, inspection.error, token, navigate]);

  return (
    <MasterLayout
      id={inspection.inspection?.simulationId}
      dateExpiration={dateExpire}
    >
      <h4>Vistoria</h4>
      {renderContent}
      <Carousel showArrows={false} showThumbs={false} dynamicHeight={false} showStatus={false}>
        <CardInformation {...cardInformationPicture1} />
        <CardInformation {...cardInformationPicture2} />
      </Carousel>

    </MasterLayout>
  );
};
export default InspectionPage;
