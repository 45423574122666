import { useEffect, useState } from 'react';
import { MasterLayout } from 'components/MasterLayout/MasterLayout';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Camera } from 'components';
import { useAppSelector } from 'store';
import crl from 'assets/images/crl.png';
import crlv from 'assets/images/crlv.png';
import { hasTokenSelector } from 'reducers/inspection';

import styles from './CRLVInformationPage.module.scss';

const CRLVInformationPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const hasToken = useAppSelector(hasTokenSelector);

  useEffect(() => {
    if (!hasToken) {
      navigate('/inspection');
    }
  }, [hasToken, navigate]);

  useEffect(() => {
    if (isSent) {
      navigate('/document-information');
    }
  }, [isSent, navigate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTakePhoto = (dataUri: string) => {
    console.log('🚀 ~ file: CRLVInformationPage.tsx ~ line 26 ~ onTakePhoto ~ dataUri', dataUri);
    setOpen(false);
    setIsSent(true);
  };

  return (
    <MasterLayout>
      <Box sx={{ flexGrow: 1 }}>
        <h4>Documento do Veículo (CRLV)</h4>
        <p>Escolha o modelo de documento </p>
        <div className="card-body border-top px-9 py-9">
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4}>
              <Button variant="contained" onClick={handleClickOpen}>
                <div className={styles.icon}>
                  <img src={crl} alt="crl" />
                </div>
                <span className="d-flex flex-column align-items-start ms-2">
                  <span className="fs-3 fw-bolder">Modelo Antigo</span>
                  <span className="fs-7">Fotografar</span>
                </span>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" onClick={handleClickOpen}>
                <div className={styles.icon}>
                  <img src={crlv} alt="crlv" />
                </div>
                <span className="d-flex flex-column align-items-start ms-2">
                  <span className="fs-3 fw-bolder">Modelo Novo</span>
                  <span className="fs-7">Anexar Documento</span>
                </span>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" onClick={handleClickOpen}>
                <div className={styles.icon}>
                  <img src={crlv} alt="crlv" />
                </div>
                <span className="d-flex flex-column align-items-start ms-2">
                  <span className="fs-3 fw-bolder">Modelo Novo Impresso</span>
                  <span className="fs-7">Fotografar</span>
                </span>
              </Button>
            </Grid>
          </Grid>
        </div>

        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Tirar foto CRLV
              </Typography>
            </Toolbar>
          </AppBar>
          <Camera onTakePhoto={onTakePhoto} example={null} index={0}/>
        </Dialog>
      </Box>
    </MasterLayout>
  );
};

export default CRLVInformationPage;
