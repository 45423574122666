import { Button, Dialog, DialogTitle } from '@mui/material';

interface props {
    open: boolean;
    close: Function;
    message: string;
}

const ConfirmationDialog = (props: props) => (
    <Dialog open={props.open}>
      <DialogTitle>{props.message}</DialogTitle>
        <Button variant='outlined' onClick={() => props.close()} >
          Ok
        </Button>
    </Dialog>
);

export default ConfirmationDialog;