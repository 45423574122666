/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'reducers/rootReducer'; // 'type' is necessary to avoid circular dependency
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getSimulation } from 'api';
import { Simulation } from '../types';

interface SimulationState {
    simulation: Simulation | null;
    id: number | null;
}

const initialState: SimulationState = { simulation: null, id: null};

export const fetchSimulation = createAsyncThunk('simulation/fetch', async (id: number, thunkAPI) => {
  try {
    return await getSimulation(id);
  } catch (error) {
    return thunkAPI.rejectWithValue(`${error}`);
  }
});

const simulationSlice = createSlice({
   name: 'simulation',
   initialState,
   reducers: {
    clearState: (state) => {
            state.simulation = null;
            state.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSimulation.fulfilled, (state, action) => {
            state.id = action.meta.arg;
            state.simulation = action.payload;
        });
    }
});

export const { clearState } = simulationSlice.actions;

export const simulationSelector = (state: RootState) => state.simulation.simulation;

export default simulationSlice.reducer;