import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'reducers/rootReducer'; // 'type' is necessary to avoid circular dependency
import { getContractInfo } from 'api';
import { ContractInfo } from '../types';

interface ContractState {
  contract: ContractInfo | null;
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: unknown;
}

const initialState: ContractState = { contract: null, status: 'idle', error: null };

export const fetchContract = createAsyncThunk('contract/fetch', async (token: string, thunkAPI) => {
  try {
    return await getContractInfo(token);
  } catch (error) {
    return thunkAPI.rejectWithValue(`${error}`);
  }
});

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContract.pending, (state) => {
      state.status = 'pending';
      state.error = null;
    });
    builder.addCase(fetchContract.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.contract = action.payload;
    });
    builder.addCase(fetchContract.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as string;
    });
  },
});

export const { clearState } = contractSlice.actions;

export const contractSelector = (state: RootState) => state.contract;

export default contractSlice.reducer;
