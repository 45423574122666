/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'reducers/rootReducer'; // 'type' is necessary to avoid circular dependency
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getInspection, postInspectionPhoto } from 'api';
import { Inspection, InspectionItemPicture, InspectionPhotoSend, PossibleStatus } from '../types';

interface status {
  status: number;
}

export interface InspectionState {
  inspection: Inspection | null | any;
  token: string | null;
  status: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
  'inspection.status'?: number;
}

type SendInspectionPhotoProps = {
  id: number;
  idInspection: number;
  photo: InspectionPhotoSend;
};

const initialState: InspectionState = { inspection: null, status: 'idle', token: null, error: null };

export const fetchInspection = createAsyncThunk('inspection/fetch', async (token: string, thunkAPI) => {
  try {
  var data = await getInspection(token);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(`${error}`);
  }
});

export const sendInspectionPhoto = createAsyncThunk(
  'inspection/send-photo',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ id, idInspection, photo }: SendInspectionPhotoProps, thunkAPI) => {
    try {
      await postInspectionPhoto(idInspection, photo);
      await new Promise((resolve) => {
        setTimeout(resolve, 3000);
      });
      return thunkAPI.fulfillWithValue(null);
    } catch (error) {
      return thunkAPI.rejectWithValue(`${error}`);
    }
  },
);

const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInspection.pending, (state) => {
      state.status = 'pending';
      state.error = null;
    });
    builder.addCase(fetchInspection.fulfilled, (state, action) => {
      state.token = action.meta.arg;
      state.status = 'succeeded';
      state.inspection = action.payload;
    });
    builder.addCase(fetchInspection.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(sendInspectionPhoto.pending, (state) => {
      state.status = 'pending';
      state.error = null;
    });
    builder.addCase(sendInspectionPhoto.fulfilled, (state, action) => {
      state.status = 'succeeded';
      const { id, photo } = action.meta.arg;
      if (state.inspection) {
        state.inspection.inspectItemsPicture =
          // eslint-disable-next-line no-confusing-arrow
          state.inspection.inspectItemsPicture?.map(
            (i: InspectionItemPicture) => (i.id === id && i.image != null ? { ...i, status: PossibleStatus.accepted, imageUri: photo.Image } : i),
            // eslint-disable-next-line function-paren-newline
          ) ?? [];
      }
    });
    builder.addCase(sendInspectionPhoto.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as string;
    });
  },
});

export const { clearState } = inspectionSlice.actions;

export const inspectionSelector = (state: RootState) => state.inspection;

export const inspectionItemSelector = (state: RootState) => state.inspection.inspection?.inspectItemsPicture ?? [];

export const hasTokenSelector = (state: RootState) => !!state.inspection.inspection;

export default inspectionSlice.reducer;
