import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import { fetchContract } from 'reducers/contract';
import { useAppDispatch } from 'store';

import AssistanceLogo from 'assets/images/Lg_Assistance_color-Vertical.png';

const ContractPage = () => {
  const [cpf, setCpf] = useState('');
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(fetchContract(token))
        .then((res) => {
          console.log('🚀 ~ file: ContractPage.tsx ~ line 14 ~ .then ~ res', res);
        })
        .catch((error) => {
          toast.error(`${error.response.statusText}`, {
            position: 'top-right',
            autoClose: false,
          });
        });
    }
  }, [dispatch, token]);

  return (
    <div className="container">
      <div className="d-flex flex-column flex-root">
        <div className="login login-3 login-signin-on d-flex flex-row-fluid" id="kt_login">
          <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid">
            <div className="login-form text-center p-7 position-relative overflow-hidden">
              <div className="d-flex flex-center mb-15">
                <a href="#">
                  <img src={AssistanceLogo} className="max-h-100px" alt="" />
                </a>
              </div>
              <div className="login-signin">
                <div className="mb-20">
                  <h3>Assinatura de Contrato</h3>
                  <p className="opacity-60 font-weight-bold">informe seu CPF ou CNPJ para continuar</p>
                </div>
                <div className="form-group">
                  <label>CPF ou CNPJ:</label>
                  <TextField
                    variant="filled"
                    className="form-control"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </div>
                <div className="form-group text-center mt-10 py-6 px-9">
                  <button
                    disabled={!cpf}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate('./view-pdf');
                    }}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractPage;
