import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { theme } from './styles/theme';
import { AppRoutes } from './routes';
import AppProvider from './contexts';

const App = () => (
  <Suspense fallback="loading">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </Router>
    </ThemeProvider>
  </Suspense>
);

export default App;
