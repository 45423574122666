import React, { useEffect, useState } from "react";
import styles from './cardInformation.module.scss';

interface props{
    title?: string;
    description?: string;
    imageSrc?: string;
    subDescription?: string;
    width: string;
}

function getWindowDimension(){
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const CardInformation = (props: props) => {
    const orientations = {
        PORTRAIT: 'portrait',
        LANDSCAPE: 'landscape',
      };

    const [orientation, setOrientation] = useState<string>(getWindowDimension().height > getWindowDimension().width ? orientations.PORTRAIT : orientations.LANDSCAPE);

    

    const handleOrientation = () => {
        var orient = '';
        if(window.innerHeight > window.innerWidth){
            setOrientation(orientations.LANDSCAPE)
            orient = orientations.LANDSCAPE;
        }else{
            setOrientation(orientations.PORTRAIT);
            orient = orientations.PORTRAIT;
        }
    }

    useEffect(() => {
        if ('onorientationchange' in window) {
        window.addEventListener("orientationchange", handleOrientation, false);
        }
    }, [orientation]);

    console.log(orientation);
    return (
        <>
            {
                orientation === orientations.PORTRAIT ? 
                    <div className={"container d-flex flex-column flex-row-fluid " + styles.cardView}>
                        <div className="d-flex flex-row flex-row-fluid" style={{fontWeight: 'bold', fontSize: 16, overflowWrap: 'break-word'}}>
                            {props.title}
                        </div>
                        <div style={{fontSize: 15}}>
                            <p>{props.description}</p>
                        </div>
                        <div className={'container d-flex flex-row flex-row-fluid ' + styles.imageExplain} >
                            <img src={props.imageSrc} className={''+styles.imageExplain}/>
                        </div>
                        <div style={{fontSize: 13, textAlign: "center"}}>
                            <p>{props.subDescription}</p>
                        </div>
                    </div>
                :
                    <div className={"d-flex flex-row flex-row-fluid " + styles.cardViewLandscape}>
                        <div className={'d-flex flex-row flex-row-fluid ' + styles.imageLandscape} >
                            <img src={props.imageSrc} className={''+styles.imageLandscape}/>
                        </div>
                        <div className="d-flex flex-column flex-row-fluid" >
                            {
                                props.title === undefined ? <></> 
                                : 
                                <div style={{fontWeight: 'bold', fontSize: 14, overflowWrap: 'break-word'}}>
                                    {`${props.title} - ${props.description}`}
                                </div>
                            }
                            <div style={{fontSize: 12, textAlign: "center"}}>
                                <p>{props.subDescription}</p>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default CardInformation;