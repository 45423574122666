import React, { useEffect, useState, useMemo } from 'react';
import { fetchSimulation, simulationSelector } from 'reducers/simulation';
import { useAppDispatch, useAppSelector } from 'store';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Timer from 'components/Timer/Timer';
import styles from './MasterLayout.module.scss';

import logoUrl from '../../assets/images/Lg_Assistance_color-Vertical.png';

interface Attributes {
  children: any;
  id?: number;
  dateExpiration?: Date;
}

const MasterLayout: React.FC<Attributes> = ({ children, id, dateExpiration }) => {
  function getWindowDimension() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const orientations = {
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape',
  };

  const simulation = useAppSelector(simulationSelector);
  const dispatch = useAppDispatch();
  const [get, setGet] = useState(false);
  const [orientation, setOrientation] = useState<string>(
    getWindowDimension().height > getWindowDimension().width ? orientations.PORTRAIT : orientations.LANDSCAPE,
  );

  const handleOrientation = () => {
    var orient = '';
    if (window.innerHeight > window.innerWidth) {
      setOrientation(orientations.LANDSCAPE);
      orient = orientations.LANDSCAPE;
    } else {
      setOrientation(orientations.PORTRAIT);
      orient = orientations.PORTRAIT;
    }
  };

  

  useEffect(() => {
    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', handleOrientation, false);
    }
  }, []);

  

  const getSimulation = async () => {
    await dispatch(fetchSimulation(id ?? 0))
      .catch((err) => {
        toast.error(`${err.response.statusText}`, {
          position: 'top-right',
          autoClose: false,
        });
      })
      .finally(() => {
        setGet(true);
      });
  };

  if (!simulation) {
    getSimulation();
    return <CircularProgress />;
  }

  return (
    <div className="page d-flex flex-row flex-column-fluid">
      <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        {orientation === orientations.PORTRAIT ? (
          <>
          <div className={'container d-flex flex-row flex-row-fluid ' + styles.headerAssistance}>
            {/* <img src={logoUrl} className={styles.imageLogo} /> */}
            <img src={'http://financeiro.assistancesolucoes.com.br/media/logos/logo_assistance.png'} className={styles.imageLogo} />
            <div className={'container d-flex flex-column flex-row-fluid ' + styles.autoVistoriaTitle}>
              AutoVistoria
              <div className={'App ' + styles.timer}>
                <div>
                  <Timer time={dateExpiration ?? new Date()} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="container d-flex flex-row flex-row-fluid"
            style={{ justifyContent: 'space-between', marginTop: 5 }}
          >
            <div className="container" style={{ fontWeight: 'bold' }}>
              {simulation.clientName}
            </div>
            <div className="container" style={{ flex: 1, fontWeight: 'bold' }}>
              {simulation.licensePlate.toUpperCase()}
            </div>
          </div>
          <div
            className="container d-flex flex-row flex-row-fluid"
            style={{ justifyContent: 'space-between', marginTop: 5 }}
          >
            <div className="container" style={{ fontWeight: 'bold' }}>
              {simulation.model}
            </div>
            <div className="container" style={{ fontWeight: 'bold', width: '28%' }}>
              {simulation.fipeModelYearId}
            </div>
          </div>
        </>
        ) : (
          <>
            <div className={'container d-flex flex-row flex-row-fluid ' + styles.headerAssistance}>
              <img src={logoUrl} className={styles.imageLogo} />
              <div className={'container d-flex flex-column flex-row-fluid ' + styles.autoVistoriaTitle}>
                AutoVistoria
                <div className={'App ' + styles.timer}>
                  <div>
                    <Timer time={dateExpiration ?? new Date()} />
                  </div>
                </div>
              </div>
              <div
                className="container d-flex flex-column flex-row-fluid"
                style={{ justifyContent: 'space-between', marginTop: 5 }}
              >
                <div className={styles.landscapeFont}>{simulation.clientName}</div>
                <div className={styles.landscapeFont}>{simulation.licensePlate.toUpperCase()}</div>
                <div
                  className="d-flex flex-row flex-row-fluid"
                  style={{ justifyContent: 'space-between', marginTop: 1 }}
                >
                  <div className={styles.landscapeFont}>{simulation.model}</div>
                  <div className={styles.landscapeFont}>{simulation.fipeModelYearId}</div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export { MasterLayout };
