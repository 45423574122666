import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Inspection, InspectionPhotoSend } from 'types';
import inspection from './inspection.json';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getInspection = async (token: string): Promise<Inspection> => {
  const { data } = await api.get(`/Inspections/inspectionAuto/${token}`);
  return data;
};

export const postInspectionPhoto = async (inspectionId: number, photo: InspectionPhotoSend): Promise<void> => {
  console.log(inspectionId);
  console.log(photo);
  await api.post(`Inspections/${inspectionId}/InspectionItemPicture`, photo);
};

export const updateFinalDate = async (token: string): Promise<void> => {
  await api.put(`Inspections/InspectionAutoFinished/${token}`);
}

// export const initiateInspectionChange = async (token: string): Promise<Date> => {
//   return await api.put(`Inspections/InspectionExpireChange/${token}`);
// }
