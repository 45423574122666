import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { InspectionItemViewModel, PossibleStatus } from 'types';

import styles from './TabPanel.module.scss';
import { useState } from 'react';
import CardInformation from 'components/CardInformation/CardInformation';

interface TabPanelProps {
  item: InspectionItemViewModel;
  index: number;
  value: number;
  setOpen: () => void;
  listTaked: boolean[];
  upload?: () => void;
  inputFile?: any;
  onChangeFile?: (event: any) => void;
}



function renderCaption(status: string) {
  if (status === PossibleStatus.notSent) {
    return (
      <div className={styles.captionWrapper}>
        <div className={styles.wrapper}>
          <QueryBuilderOutlinedIcon color="info" className={styles.icon} />
          <Typography variant="caption" className={styles.caption}>
            Não enviada
          </Typography>
        </div>
      </div>
    );
  }
  if (status === PossibleStatus.pending) {
    return (
      <div className={styles.captionWrapper}>
        <div className={styles.wrapper}>
          <QueryBuilderOutlinedIcon color="info" className={styles.icon} />
          <Typography variant="caption" className={styles.caption}>
            Enviado
          </Typography>
        </div>
      </div>
    );
  }
  if (status === PossibleStatus.accepted) {
    return (
      <div className={styles.captionWrapper}>
        <div className={styles.wrapper}>
          <CheckCircleOutlinedIcon color="success" className={styles.icon} />
          <Typography variant="caption" className={styles.caption}>
            Aprovado
          </Typography>
        </div>
      </div>
    );
  }
  if (status === PossibleStatus.rejected) {
    return (
      <div className={styles.captionWrapper}>
        <div className={styles.wrapper}>
          <CancelOutlinedIcon color="error" className={styles.icon} />
          <Typography variant="caption" className={styles.caption}>
            Rejeitado
          </Typography>
        </div>
      </div>
    );
  }
  return null;
}

const renderImage = (item: InspectionItemViewModel, objStatus: string) => {

  const [exampleImage, setExampleImage] = useState(false);

  const showExampleImage = () => {
    setExampleImage(!exampleImage);
  }

  if(objStatus === PossibleStatus.pending){
    return (
      <div className={styles.sendedItem} onClick={showExampleImage}>
        {
          exampleImage ? 
          <div className={styles.photoLandscapImageContainer}>
            <img src={item.imageUrl ?? ''} alt={item.description}/>
            {renderCaption(objStatus)}
            <Typography variant="caption" className={styles.exampleCaption}>
              Exemplo
            </Typography>
          </div>
          : <></>
        }
        <div className={styles.photoSendedStyle}>
          FOTO ENVIADA
        </div>
      </div>
    );
  }
  if (item.imageUrl && objStatus === PossibleStatus.notSent) {
    return (
      <div className={styles.imageContainer}>
        <img src={item.imageUrl ?? ''} alt={item.description} width={'10%'}/>
        {renderCaption(objStatus)}
      </div>
    );
  }
  if(item.image){
    return(
    <div className={styles.imageContainer}>
        <img src={item.image} alt={item.description} />
        {renderCaption(objStatus)}
    </div>
    );
  }
  if (item.imageUrl) {
    return (
      <div className={styles.imageContainer}>
        <img src={item.imageUrl} alt={item.description}/>
        {renderCaption(objStatus)}
        <Typography variant="caption" className={styles.exampleCaption}>
          Exemplo
        </Typography>
      </div>
    );
  }
  return null;
};

const TabPanel = ({ item, value, index, setOpen, ...other }: TabPanelProps) =>{
  var statusObj = null;
  if(other.listTaked[index]){
    statusObj = PossibleStatus.pending;
  }else{
    statusObj = item.status;
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.container} sx={{ p: 1 }}>
          <Typography variant="h6">{item.description}</Typography>
          {renderImage(item, statusObj)} 
          <Button
            disabled={statusObj === PossibleStatus.pending || statusObj === PossibleStatus.accepted}
            variant="outlined"
            onClick={setOpen}
            endIcon={<PhotoCamera />}
          >
            Tirar Foto
          </Button>
          {item.allowUpload  ? (
            <Button
            disabled={statusObj === PossibleStatus.pending || statusObj === PossibleStatus.accepted}
            variant="outlined"
            onClick={other.upload}
            endIcon={<AttachFileIcon />}
            sx={{marginTop:1}}
            >
              <input type='file' id='image' ref={other.inputFile} style={{display: 'none'}} onChange={other.onChangeFile} accept="image/jpg,image/png, application/pdf,image/x-eps"/>
              Escolher arquivo
            </Button>
          ): null}
          
          {item.longDescription === null ? <></> : (
            <div className={styles.cardContainer}>
              <CardInformation subDescription={item.longDescription ?? ''} width={'100%'}/>
            </div>
          )}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
