import navigationUrl from 'assets/images/navigationButtons.png';


export const cardInformationPicture1 = {
    title: 'Fotos',
    description: 'Tire as fotos seguindo as orientações',
    imageSrc: 'https://media.istockphoto.com/vectors/taking-photo-on-cellphone-white-background-hand-holding-mobile-vector-id1212129156?k=20&m=1212129156&s=170667a&w=0&h=UdJdXxttheU4naTK1XxwT8M3p8Ssf7bFaaixaV-AvAo=',
    subDescription: 'Sempre tire a foto com o celular deitado (orientação paisagem, como mostra a imagem acima) de forma que consiga enquadrar bem o veiculo',
    width: '80%'
};

export const cardInformationPicture2 = {
    title: 'Navegação',
    description: 'Navegue entre as fotos',
    imageSrc: navigationUrl,
    subDescription: 'Enquanto estiver fazendo a vistoria utilize os botões "Próximo" e "Voltar" para navegar entre as opções',
    width: '80%'
};