import {
  ContractPage,
  InspectionPage,
  CRLVInformationPage,
  DocumentInformationPage,
  RejectedToken,
  CompletedInspection,
  ViewPDFPage,
} from 'pages';

export const publicRoutes = [
  {
    path: '/contract/:token',
    element: <ContractPage />,
  },
  {
    path: '/contract/:token/view-pdf',
    element: <ViewPDFPage />,
  },
  {
    path: '/inspection/:token',
    element: <InspectionPage />,
  },
  {
    path: '/crlv-information',
    element: <CRLVInformationPage />,
  },
  {
    path: '/document-information',
    element: <DocumentInformationPage />,
  },
  {
    path: '/rejected-token',
    element: <RejectedToken />,
  },
  {
    path: '/completed-inspection',
    element: <CompletedInspection />,
  },
];
