import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';

interface props {
    time: Date;
}

const Timer: React.FC<props> = (props) => {
    const navigate = useNavigate();
     
    const [timer, setTimer] = useState('##:##:##');


    function timeBetweenDates() {
    var dateEntered = props.time;
    var now = new Date();
    var difference = dateEntered.getTime() - now.getTime();
    
    var seconds = Math.floor(difference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    
    hours %= 24;
    minutes %= 60;
    seconds %= 60;

    var hoursForm = hours <= 9 ? '0'+ (hours + (days * 24)): (hours + (days * 24));
    var minutesForm = minutes <= 9 ? '0' + minutes : minutes;
    var secondForm = seconds <= 9 ? '0'+seconds : seconds;

    setTimer(`${hoursForm}:${minutesForm}:${secondForm}`);
    }

    useEffect(() => {
        setInterval(()=>{
            timeBetweenDates();
          }, 1000);
        // if(timer === '00:00:00' || timer === 'NaN:NaN:NaN'){
        //     navigate('/rejected-token');
        // }
    }, []);


    if(timer === '00:00:00'){
        navigate('/rejected-token');
    }

    if(timer.includes('-')){
        timeBetweenDates();
    }

    return (
        <div style={{color: 'red'}}>
            {timer}
        </div>
    );
}

export default Timer;