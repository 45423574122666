import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import { MasterLayout } from 'components/MasterLayout/MasterLayout';
import { inspectionItemSelector, inspectionSelector, InspectionState, sendInspectionPhoto } from 'reducers/inspection';
import { useAppDispatch, useAppSelector } from 'store';
import { Inspection, InspectionItemPicture, InspectionPhotoSend, PossibleStatus } from 'types';
import { Camera } from 'components';
import { TabPanel } from './components/TabPanel';
import moment from 'moment';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { CircularProgress, DialogActions } from '@mui/material';
import { updateFinalDate } from 'api';
import MobileDetect from 'mobile-detect';

const possiblyOS = {
  Chrome: 'Chrome'
}

const InspectionWizardPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<InspectionItemPicture | null>(null);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const { token: storedToken, inspection } = useAppSelector(inspectionSelector);
  var inspectionItems: InspectionItemPicture[] = useAppSelector(inspectionItemSelector);
  const [dialog, setDialog] = useState(false);
  const [listTaked, setListTaked] = useState<boolean[]>([]);
  const [qtdPhotosTaked, setQtdPhotosTaked] = useState(0);
  const [qtdPhotosNotTaked, setQtdPhotosNotTaked] = useState(0);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState();
  const [picS, setPicS] = useState('none');
  const reader = new FileReader();
  const [isIos, setIsIos] = useState(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  
  

  useEffect(() => {
    if (token !== storedToken) {
      navigate(`/inspection/${token}`);
    } else {
      const index = inspectionItems?.findIndex((item) => item.status === PossibleStatus.pending 
        || item.status === PossibleStatus.rejected
        || item.status === PossibleStatus.notSent
      );
      setValue(index ?? 0);
    }
    getDeviceOs();
    var qtdImagesNotTaked: number = inspectionItems.filter(function(item){
      return item.status === PossibleStatus.notSent;
    }).length;
    setQtdPhotosNotTaked(qtdImagesNotTaked);
    navigator.geolocation.getCurrentPosition(function(position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
  }, [storedToken, navigate, token]);


  const handleBack = () => {
    setValue((prevActiveStep) => prevActiveStep - 1);
  };

  const getDeviceOs = () => {
    const detect = new MobileDetect(window.navigator.userAgent);
    const navigatorDetected = detect.userAgent();
    console.log(navigatorDetected);
    if(navigatorDetected != possiblyOS.Chrome){
      setIsIos(true);
    }
  }

  const handleNext = () => {
    if(value < inspectionItems.length){
      setValue(value + 1);
    }
  };

  const handleClickOpen = (item: InspectionItemPicture) => {
    if(!isIos){
      document.body.requestFullscreen().then(()=> {
      var t = window.screen.orientation;
      t.lock('landscape');
      setActiveItem(item);
      setOpen(true);
      })
    }else{
      setActiveItem(item);
      setOpen(true);
    }
  };

  const chooseFile = (item:any) => {
    setActiveItem(item);
    inputFile?.current?.click();
  }

  const fileChange = (event: any) => {
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      // console.log(reader.result?.toString());
      console.log(activeItem?.id);
      // console.log(inspection?.inspectionId);
      // console.log(activeItem?.inspectionItemId);
      // console.log(activeItem?.description);
      // console.log(activeItem?.latitude);
      // console.log(activeItem?.longitude);
      // console.log(value);
      onTakePhoto(
        activeItem?.id ?? 0, 
        inspection?.inspectionId ?? 0,
        {
          InspectionItemId: activeItem?.inspectionItemId ?? 0,
          Description: activeItem?.description ?? '',
          Latitude: Number(activeItem?.latitude ?? 0),
          Longitude: Number(activeItem?.longitude ?? 0),
          Image: (reader.result?.toString() ?? ''),
          CapturedAt: new Date().toJSON()
        },
        value
      );
    }
  }

  const handleClose = () => {
    var t = window.screen.orientation;
    t.unlock();
    document.exitFullscreen();
    setOpen(false);
  };

  const handleFinish = () => {
    if(qtdPhotosTaked < qtdPhotosNotTaked){
        setDialog(true)
      }else{
        updateFinalDate(token ?? '');
        navigate('/completed-inspection');
      }
  }

  const handleCloseDialog = () => {
    setDialog(false);
  }

  // Metodo para girar imagem
  // const rotateImage = (imageBase64: string, rotation: number, cb: ((arg0: string) => void)) => {
  //   var img = new Image();
  //    img.src = imageBase64;
  //    img.onload = () => {
  //      var canvas = document.createElement("canvas");
  //      const maxDim = Math.max(img.height, img.width);
  //      if ([90, 270].indexOf(rotation) > -1) {
  //        canvas.width = img.height;
  //        canvas.height = img.width;
  //      } else {
  //        canvas.width = img.width;
  //        canvas.height = img.height;
  //      }
  //      var ctx = canvas.getContext("2d");
  //      ctx?.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
  //      ctx?.rotate(90 * (Math.PI / 180));
  //      ctx?.drawImage(img, -maxDim / 2, -maxDim / 2);
  //      cb(canvas.toDataURL("image/jpeg"))
  //    };
  // }


  const onTakePhoto = async (id: number, idInspection: number, photoData: InspectionPhotoSend, indexTaked: number) => {
    let newTaked = [...listTaked];
    newTaked[indexTaked] = true;
    setListTaked(newTaked);
    setIsLoading(true);
    // rotateImage(photoData.Image, 0, (url) => {
    //   console.log(url);
    // });
    console.log(photoData.Image);
    try {
      setPicS('flex');
      await dispatch(sendInspectionPhoto({ id, idInspection, photo: photoData })).finally(() => {
        setPicS('none');
      });
      setQtdPhotosTaked(qtdPhotosTaked + 1);
      handleNext();
    } catch (e) {
      toast.error(`${e}`, {
        position: 'top-right',
        autoClose: false,
      });
    } finally {
      setOpen(false);
      setIsLoading(false);
      inspectionItems = useAppSelector(inspectionItemSelector);
      
    }
  };

  return (
    <MasterLayout id={inspection?.simulationId} dateExpiration={new Date(Date.parse(inspection?.expiresAt ?? ''))}>
      <Box sx={{ width: '100%'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs">
            {inspectionItems?.map(({ id, description }, index) => (
              <Tab style={{ cursor: 'default' }} value={index} key={id} label={description} wrapped />
            ))}
          </Tabs>
        </Box>
        <div>
          {inspectionItems?.map((item, index) => {
            return (
            <TabPanel 
              key={item.id} value={value} 
              index={index} item={item} 
              setOpen={() => handleClickOpen(item)}
              listTaked={listTaked}
              upload={() => chooseFile(item)}
              inputFile={inputFile}
              onChangeFile={fileChange}
            />
          );})}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
            <Button variant="outlined" disabled={value === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Voltar
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {(inspectionItems?.length - 1 ?? 0) > value ? (
              <Button
                //disabled={inspectionItems?.length ? inspectionItems[value].status === PossibleStatus.pending : false}
                variant="contained"
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                Próximo
              </Button>
            ) : (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleFinish()}
              >
                Finalizar
              </Button>
            )}
          </Box>
        </div>
      </Box>
      {open && activeItem && (
        <Dialog fullScreen open={open} onClose={handleClose} >
          <Box sx={{
            height: '100vh',
            width: '100%',
            display: picS,
            backgroundColor: '#FFFFFF99',
            position: 'absolute',
            zIndex: '1000',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CircularProgress />
          </Box>
          <AppBar sx={{ position: 'absolute', backgroundColor: '#FFFFFF00', boxShadow: '0px 0px 0px #FFFFFF00'}}>
            <Toolbar >
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                TIRAR FOTO {activeItem.description}
              </Typography>
            </Toolbar>
          </AppBar>
          <Camera
            index={value}
            onTakePhoto={async (uri: string) => {
              // console.log('latitude: ', coords?.latitude ?? 0);
              // console.log('longitude: ', coords?.longitude ?? 0);
              await onTakePhoto(
                activeItem.id, 
                inspection?.inspectionId ?? 0,
                {
                  InspectionItemId: activeItem.inspectionItemId,
                  Description: activeItem.description,
                  Latitude: latitude,
                  Longitude: longitude,
                  Image: uri,
                  CapturedAt: new Date().toJSON()
                },
                value,
              );
            }}
            loading={isLoading}
            example={activeItem.imageUrl}
            selfieMode={activeItem.selfieMode}
            isIos={isIos}
          />
        </Dialog>
      )}
      <ConfirmationDialog open={dialog} close={handleCloseDialog} message={"É necessário tirar todas as fotos para finalizar"}/>
    </MasterLayout>
  );
};

export default InspectionWizardPage;

