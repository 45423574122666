import axios, { AxiosError /* , AxiosRequestConfig */ } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import storage from './storage';

export const BASE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;
const ORIGIN = process.env.REACT_APP_ORIGIN as string;
var errorCount = 0;


const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ORIGIN,
  },
});

const errorHandler = async (error: Error | AxiosError) => {
  const navigate = useNavigate();
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 500) {
      toast.error(`${error.response.statusText}`, {
        position: 'top-right',
        autoClose: false,
      });
    }
    if (!error.response && errorCount === 0) { 
      toast.error(`Network Error! Api is not reachable. Check if service is running on url ${BASE_API_URL}`, {
        position: 'top-right',
        autoClose: false,
      });
      errorCount += 1;
    }
  }
  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error),
);

// api.interceptors.request.use((config: AxiosRequestConfig) => {
//   if (config.headers && !config.headers.authorization) {
//     const user = storage.getItem<User>('user');
//     config.headers.Authorization = `Bearer ${user === null ? '' : user.id}`;
//   }

//   return config;
// });

export default api;
