export interface Inspection {
  id: number;
  token: string;
  createdAt: string;
  expiresAt: string;
  inspectionModelId: number;
  finishedAt: string | null;
  inspectionId: number;
  simulationId: number;
  started?: boolean;
  inspectItemsPicture: InspectionItemPicture[];
}

interface Status {
  notSent: string;
  pending: string;
  accepted: string;
  rejected: string;
}

export const PossibleStatus: Status = {
  notSent: 'NOTSENT',
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
}

export interface InspectionItemPicture {
  id: number;
  inspectionId: number | null;
  inspectionItemId: number;
  description: string;
  latitude: string | null;
  longitude: string | null;
  image: string;
  createdAt: string;
  inspectionItem: string | null;
  imageUrl: string | null;
  rejectedDate: string | null;
  index: number;
  status:'NOTSENT' | 'PENDING' | 'ACCEPTED' | 'REJECTED' | string;
  /**
   * NOTSENT - Não enviado
   * PENDING - Enviado e aguardando AWS
   * ACCEPTED - Aceito
   * REJECTED - Rejeitado
   */
  longDescription: string | null;
  selfieMode: boolean | null;
  allowUpload: boolean | null;
}

export interface InspectionPhotoSend {
  InspectionItemId: number;
  Description: string;
  Latitude: number;
  Longitude: number;
  Image: string;
  CapturedAt: string;
}

export interface InspectionItemViewModel extends InspectionItemPicture {
  imageUri?: string;
}
