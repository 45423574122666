import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import styles from './ViewPDFPage.module.scss';

const ViewPDFPage = () => {
  const [numPages, setNumPages] = useState(null);
  const [checked, setChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const signDocument = () => {
    toast.success('Documento assinado com sucesso!', {
      position: 'top-right',
      autoClose: false,
    });
    setDialogOpen(true);
  };

  return (
    <>
      <div className={styles.container}>
        <Document className={styles.document} file="/test-pdf.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div>
          <p>
            Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
          </p>
          <Button className={styles.pageButton} variant="contained" disabled={pageNumber <= 1} onClick={previousPage}>
            Anterior
          </Button>
          {pageNumber === numPages ? (
            <Button className={styles.pageButton} variant="contained" color="success" onClick={openDialog}>
              Assinar
            </Button>
          ) : (
            <Button
              className={styles.pageButton}
              variant="contained"
              disabled={pageNumber >= (numPages ?? 0)}
              onClick={nextPage}
            >
              Próximo
            </Button>
          )}
        </div>
      </div>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>Assinar Contrato</DialogTitle>
          <DialogContent>
            <DialogContentText>Confirme a assinatura do documento</DialogContentText>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox value={checked} onChange={() => setChecked((c) => !c)} />}
                label="Li e concordo com as informações do contrato"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button disabled={!checked} onClick={signDocument} autoFocus>
              Assinar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ViewPDFPage;
