import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { persistor, store } from './store';
import App from './App';

import './_metronic/assets/sass/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-html5-camera-photo/build/css/index.css';
import './styles/global.scss';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading="Loading" persistor={persistor}>
        <ToastContainer position="top-right" newestOnTop theme="colored" />
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
