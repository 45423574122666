// import api from 'services/api';
import { ContractInfo } from 'types';

export const getContractInfo = async (token: string): Promise<ContractInfo> => {
  //   const { data } = await api.get(`/contract/${token}`);
  const data = {
    id: '123',
    token,
  };
  return data;
};
