import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CameraComp, { FACING_MODES } from 'react-html5-camera-photo';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './Camera.module.scss';

interface CameraProps {
  onTakePhoto: (dataUri: string) => void;
  example: string | null;
  loading?: boolean;
  index: number;
  selfieMode?: boolean | null;
  deviceOS?: string;
  isIos?: boolean | null;
}

const Camera = ({ onTakePhoto, example, loading, index, selfieMode, isIos }: CameraProps) => {
  const [dataUri, setDataUri] = useState<string[]>([]);
  const [exampleImg, setExampleImg] = useState<boolean>(false);
  var uriL: string[] = [];

  function handleTakePhoto(uri: string) {
    uriL[index] = uri;
    setDataUri(uriL);
  }

  function removePhotoIndex() {
    uriL.splice(index, 1);
    setDataUri(uriL);
  }

  const showExample = () => {
    setExampleImg(!exampleImg);
  };

  // const changeLatLong = () => {
  //   const { coords, isGeolocationAvailable, isGeolocationEnabled } = 
  //   useGeolocated({positionOptions: {enableHighAccuracy: false,},userDecisionTimeout: 5000,});
  //   console.log(coords);
  //   setLatitude(coords?.latitude ?? 0);
  //   setLongitude(coords?.longitude ?? 0);
  // }

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function(position) {
  //     console.log("Latitude is :", position.coords.latitude);
  //     console.log("Longitude is :", position.coords.longitude);
  //   });
  // }, [])

  const renderCamera = () =>
    isIos ? (
      selfieMode ? (
        <CameraComp
          imageCompression={1}
          idealFacingMode={FACING_MODES.USER}
          onTakePhoto={(uri) => handleTakePhoto(uri)}
          isImageMirror={false}
          isFullscreen
          onCameraError={(error) => console.log(error)}
          
        />
      ) : (
        <CameraComp
          imageCompression={1}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          onTakePhoto={(uri) => handleTakePhoto(uri)}
          isImageMirror={false}
          isFullscreen
          onCameraError={(error) => console.log(error)}
          
        />
      )
    ) : (
      selfieMode ? (
        <CameraComp
          idealResolution={{ width: 1280, height: 720 }}
          imageCompression={1}
          
          idealFacingMode={FACING_MODES.USER}
          onTakePhoto={(uri) => handleTakePhoto(uri)}
          isImageMirror={false}
          isFullscreen
          onCameraError={(error) => console.log(error)}
          
        />
      ) : (
        <CameraComp
          idealResolution={{ width: 1280, height: 720 }}  
          imageCompression={1}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          onTakePhoto={(uri) => handleTakePhoto(uri)}
          isImageMirror={false}
          isFullscreen
          onCameraError={(error) => console.log(error)}
          
        />
      )
    );

  return (
    <div className={styles.container}>
      {example && exampleImg ? (
        <div className={styles.example} onClick={showExample}>
          <Typography variant="caption" className={styles.exampleCaption}>
            Exemplo
          </Typography>
          <img src={example} alt="preview" />
        </div>
      ) : (
        <div className={styles.example + ' ' + styles.btnShowExample} onClick={showExample}>
          Exemplo
        </div>
      )}
      {dataUri[index] ? (
        <div className={styles.imageContainer}>
          <img {...(loading ? { className: styles.gray } : {})} src={dataUri[index]} alt="preview" />
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={() => removePhotoIndex()}
              sx={{ backgroundColor: '#FF0000', color: '#FFF' }}
            >
              Tirar outra
            </Button>
            <Button variant="contained" onClick={() => onTakePhoto(dataUri[index])}>
              Salvar
            </Button>
          </div>
        </div>
      ) : (
        renderCamera()
      )}
      {loading && <CircularProgress size={68} className={styles.loading} />}
    </div>
  );
};

Camera.defaultProps = {
  loading: false,
};

export default Camera;
function componentDidMount() {
  throw new Error('Function not implemented.');
}

