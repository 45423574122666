import React from 'react';
import SVG from 'react-inlinesvg';

type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
};

const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = 'mh-50px' }) => (
  <span className={`svg-icon ${className}`}>
    <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
  </span>
);

KTSVG.defaultProps = {
  className: '',
  svgClassName: 'mh-50px',
};

export default KTSVG;
