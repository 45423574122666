import styles from './RejectedToken.module.scss';

const logoURL = "http://financeiro.assistancesolucoes.com.br/media/logos/logo_assistance.png";

const RejectedToken = () => {
    return (
        <div className='d-flex flex-column'>
            <div className={'container ' + styles.rejectedHeader}>
                <img src={logoURL}/>
            </div>
            <div className={'container ' + styles.viewTitle}>
                <div className={styles.titleToken}>Token Expirado</div>
            </div>
            <div className={styles.cardInformation}>
                <div className={styles.information}>
                    Entre em contato com o consultor e solicite que seja gerado um novo token.
                </div>
            </div>
        </div>
        
    );
}

export default RejectedToken;