import { useRoutes } from 'react-router-dom';
import { hasTokenSelector } from 'reducers/inspection';
import { useAppSelector } from 'store';
// import { isLoggedInSelector } from 'features/auth/reducer/auth';

import { protectedRoutes } from './protected.routes';
import { publicRoutes } from './public.routes';

export const AppRoutes = () => {
  // const isLoggedIn = useAppSelector(isLoggedInSelector);
  const hasToken = useAppSelector(hasTokenSelector);

  const routes = hasToken ? [...protectedRoutes, ...publicRoutes] : publicRoutes;

  return useRoutes(routes);
};
