import styles from './CompletedInspectionPage.module.scss';

const logoURL = "http://financeiro.assistancesolucoes.com.br/media/logos/logo_assistance.png";


const CompletedInspection = () => {
    return (
        <div className='d-flex flex-column'>
            <div className={'container ' + styles.rejectedHeader}>
                <img src={logoURL}/>
            </div>
            <div className={'container ' + styles.viewTitle}>
                <div className={styles.titleToken}>Vistoria Finalizada</div>
            </div>
            <div className={styles.cardInformation}>
                <div className={styles.information}>
                    Aguarde a conferencia das fotos
                </div>
            </div>
        </div>
        
    );
}

export default CompletedInspection;