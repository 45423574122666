import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import contractReducer from './contract';
import inspectionReducer from './inspection';
import simulationReducer from './simulation';

const appReducer = combineReducers({ contract: contractReducer, inspection: inspectionReducer, simulation: simulationReducer });

const rootReducer = (state: RootState | undefined, action: AnyAction) => appReducer(state, action);

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
