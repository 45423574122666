interface InvalidInspectionProps {
  token: string;
  error: string;
}

const InvalidInspection = ({ token, error }: InvalidInspectionProps) => (
  <div>
    <p>Token {token} inválido ou expirado, tente novamente</p>
    <p>Error:</p>
    <p>{error}</p>
  </div>
);

export default InvalidInspection;
